@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&family=Roboto:wght@300;400;500&display=swap');

.language-selector {
    display: flex;
    align-items: center;
    gap: 2rem;
    margin: 2rem 0;
}

.language-option {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
    transition: color 0.3s ease;
}

.language-option.unselected {
    color: #5B5B5B;
}

h1,
h2,
h3,
.skill-item,
.language-selector {
    font-family: 'Press Start 2P', cursive;
}

p,
.period,
.detail-item,
.company-name,
.school-name {
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    line-height: 1.5;
}

.selector-text {
    font-size: 1.2rem;
}

.language-arrow {
    font-size: 1.2rem;
}

.resume-container {
    max-width: 1000px;
    margin: 2rem auto;
    padding: 2rem;
    border: 2px solid white;
}

/* Header Section */
.header-section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    margin-bottom: 3rem;
    padding-bottom: 2rem;
    border-bottom: 2px solid white;
}

.header-left h2 {
    font-size: 1.8rem;
    margin-bottom: 1rem;
}

.header-left h3 {
    font-size: 1.2rem;
    margin-bottom: 1rem;
    color: #CCCCCC;
}

.header-left p {
    color: #AAAAAA;
    line-height: 1.5;
}

.header-right {
    text-align: right;
}

.header-right p {
    margin-bottom: 0.5rem;
    color: #CCCCCC;
}

/* Resume Sections */
.resume-section {
    margin-bottom: 3rem;
}

.resume-section h2 {
    font-size: 1.5rem;
    margin-bottom: 2rem;
    padding-bottom: 0.5rem;
    border-bottom: 2px solid white;
}

/* Experience & Education Items */
.experience-item,
.education-item,
.project-item {
    margin-bottom: 2rem;
}

.experience-header,
.education-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
}

.experience-header h3,
.education-header h3 {
    font-size: 1.2rem;
}

.period {
    color: #AAAAAA;
    font-size: 0.9rem;
}

.company-name,
.school-name {
    color: #CCCCCC;
    font-size: 1.1rem;
    font-weight: 500;
}

.project-details {
    margin-top: 1rem;
}

.project-title {
    color: #CCCCCC;
    margin-bottom: 0.5rem;
}

.detail-item {
    color: #AAAAAA;
    margin-left: 1rem;
    margin-bottom: 0.5rem;
    font-size: 0.95rem;
}

/* Projects Section */
.project-name {
    font-size: 1.1rem;
    margin-bottom: 0.5rem;
}

.home-link {
    color: white;
    text-decoration: none;
    transition: opacity 0.3s ease;
}

.home-link:hover {
    opacity: 0.8;
}

/* Skills Section */
.skills-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
}

.skills-group h3 {
    margin-bottom: 1rem;
    font-size: 1rem;
}

.skills-list {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
}

.skill-item {
    background: #222;
    padding: 0.5rem 1rem;
    border: 1px solid white;
    font-size: 0.8rem;
}

.contact-link {
    color: white;
    text-decoration: none;
    transition: color 0.3s ease;
    position: relative;
}

.contact-link:hover {
    color: white;
}

.contact-link::after {
    color: white;
    content: '';
    position: absolute;
    width: 0;
    height: 1px;
    bottom: -2px;
    left: 0;
    background-color: white;
    transition: width 0.3s ease;
}

.contact-link:hover::after {
    width: 100%;
}

@media (max-width: 768px) {
    .header-section {
        grid-template-columns: 1fr;
    }

    .header-right {
        text-align: left;
    }

    .experience-header,
    .education-header {
        flex-direction: column;
        align-items: flex-start;
        gap: 0.5rem;
    }

    .resume-container {
        padding: 1rem;
    }

    .skills-container {
        grid-template-columns: 1fr;
    }

}